import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">

                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i>
                        <h2>Portfolio Manager</h2>
                        <p>I focus on crafting tailored investment strategies, aiming for consistent growth
                            through careful financial analysis and informed decision-making.</p>
                        <span className="projects">+7 years of expirience</span>
                    </div>

                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-atom"></i>
                        <h2>Data Science</h2>
                        <p>I develop AI-driven tools and financial solutions to enhance investment
                            strategies and decision-making processes.</p>
                        <span className="projects">+100 Financial solutions</span>
                    </div>


                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Development</h2>
                        <p>I develop and deploy applications through web platforms, providing an
                            efficient and smooth digital experience for users.</p>
                        <span className="projects">+5 Projects</span>
                    </div>


                </div>
            </div>
        </div>
    </section>
  )
}
