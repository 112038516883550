import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Every successful investment  <br/>
                        starts <span>with a well-informed strategy</span></h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>I have worked in wealth management and
                    collaborated with experts to develop AI-driven solutions for investors and businesses
                    alike. I’m consistently focused, naturally analytical, and always refining my skills
                    to solve one financial challenge at a time.</p>
            </div>
        </div>
    </section>
  )
}
