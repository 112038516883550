import React from 'react'

export default function Lsb() {
  return (
      <div className="left-sidebar">
          <div className="sidebar-header d-flex align-items-center justify-content-between">
              <img src="./assets/images/logo.svg" alt="Logo" style={{width: '45px', height: 'auto'}}/>
              <span className="designation">Portfolio Manager & Data Science</span>
          </div>
          <img className="me" src="./assets/images/me.jpg" alt="Me"/>
          <h2 className="email">handielgongora@gmail.com</h2>
          <h2 className="address">Base in Panama, PA</h2>
          <p className="copyright">&copy; Handiel Luis Góngora Quevedo</p>
          <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
              <li>
                  <a href="https://x.com/handielgongora"><i className="lab la-twitter"></i></a>
              </li>
              <li>
                  <a href="https://www.instagram.com/handielgongora/"><i className="lab la-instagram"></i></a>
              </li>
              <li>
                  <a href="https://wa.me/+50767253660" target="_blank" rel="noopener noreferrer">
                      <i className="lab la-whatsapp"></i>
                  </a>
              </li>

          </ul>
          <a href="#contact" className="theme-btn">
              <i className="las la-envelope"></i> Contact me!
          </a>
      </div>
  )
}   
