import React, { useState, useEffect } from 'react';

const colorOptions = [
  '#28e98c',
  '#e4af12',
  '#fe6f1d',
  '#0A96FF', // Este será el color por defecto
  '#c0c0c0',
  '#1338f3',
  '#f31313',
  '#ff99cc'
];

const Settings = (props) => {
  const [activeColor, setActiveColor] = useState('#14c5fd'); // Color por defecto #14c5fd
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // La configuración está cerrada por defecto

  const handleColorClick = (color) => {
    setActiveColor(color); // Cambia el color activo
    setIsSettingsOpen(false); // Cierra la configuración automáticamente al seleccionar un color
    document.documentElement.style.setProperty('--primary_color', color); // Aplica el color a la propiedad CSS
  };

  useEffect(() => {
    // Establece el fondo por defecto y el video al cargar la página
    document.querySelector('body').style.backgroundColor = 'black';
    props.clickEvent('./assets/images/video1.mp4'); // Establece el video predeterminado
    document.documentElement.style.setProperty('--primary_color', activeColor); // Aplica el color por defecto
  }, []); // El array vacío asegura que esto se ejecute solo una vez al montar el componente

  return (
    <div className={`global-color ${isSettingsOpen ? 'active' : ''}`}>
      <span className="setting-toggle" onClick={() => setIsSettingsOpen(true)}>
        <i className="las la-cog"></i>
      </span>
      <div className="inner">
        <div className="overlay" onClick={() => setIsSettingsOpen(false)}></div>
        <div className="global-color-option">
          <span className="close-settings" onClick={() => setIsSettingsOpen(false)}>
            <i className="las la-times"></i>
          </span>
          <h2>Configuration</h2>
          <div className="global-color-option-inner">
            <p>Colors</p>
            <div className="color-boxed">
              {colorOptions.map((color) => (
                <a key={color} href="#" className={activeColor === color ? 'clr-active' : ''} onClick={() => handleColorClick(color)}></a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
