import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2022 - Present</span>

                        <h2>Portfolio Manager & Developer</h2>
                        <p>Quest Wealth Advisers, Inc.</p>
                        <h2>CFA® Candidate</h2>
                        <p>CFA® Institute</p>
                        <h2>CFA® Data Science for Investment Professionals Certificate</h2>
                        <p>CFA® Institute</p>
                        <h2>Master's in Full Stack Web Development</h2>
                        <p>Conquer Blocks School</p>
                    </div>

                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2017 - 2022</span>
                        <h2>Financial Analyst</h2>
                        <p>Family Office</p>
                        <h2><a href="https://basno.com/hlb6ciqm" target="_blank">CFA® Level I Badge</a></h2>
                        <p>CFA® Institute</p>
                        <h2><a href="https://basno.com/3ljur5f1" target="_blank">CFA® Investment Foundations Certificate
                            Badge</a></h2>
                        <p>CFA® Institute</p>
                        <h2>Master of Business Administration - MBA</h2>
                        <p>ADEN International Business School</p>

                        <h2><a
                            href="https://www.linkedin.com/in/handiel-luis-g%C3%B3ngora-quevedo-674b1a122/details/education/1598974909428/single-media-viewer/?profileId=ACoAAB5r_DgBe8_ytNkvWJz9l1D6SAybI9keWZg"
                            target="_blank">Predictive Machine Learning Certificate</a></h2>
                        <p>Data Science For Business School</p>
                    </div>


                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2010 - 2017</span>
                        <h2>Department Director</h2>
                        <p>Blue Diamond</p>
                        <h2>Bachelor's Degree in Food Science and Technology</h2>
                        <p>Bachelor's Degree</p>


                    </div>

                </div>

            </div>
        </div>
    </section>
  )
}
